import React, { useEffect } from 'react'
import * as THREE from '../../node_modules/three/build/three.module.js';

const showPanorama = (width, image, id) => {
    var camera, scene, renderer;
    var isUserInteracting = false,
        onMouseDownMouseX = 0, onMouseDownMouseY = 0,
        lon = 0, onMouseDownLon = 0,
        lat = 0, onMouseDownLat = 0,
        phi = 0, theta = 0;
    var containerWidth
    var container = document.getElementById(id)
    if (width === undefined) {
        containerWidth = container.parentNode.clientWidth
    } else {
        containerWidth = width
    }
    if (container.parentNode.className === "modal-body") {
        containerWidth = container.parentNode.clientWidth - 32
    }
    if (container.parentNode.className === "col-md-12") {
        containerWidth = container.parentNode.clientWidth - 32
    }
    init();
    animate();


    function init() {
        var mesh;
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
        camera.target = new THREE.Vector3(0, 0, 0);
        scene = new THREE.Scene();
        var geometry = new THREE.SphereBufferGeometry(400, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry.scale(- 1, 1, 1);
        var texture = new THREE.TextureLoader().load(image);
        var material = new THREE.MeshBasicMaterial({ map: texture });
        mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);
        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(containerWidth, containerWidth / 16 * 9);
        container.appendChild(renderer.domElement);

        container.addEventListener('mousedown', onPointerStart, false);
        container.addEventListener('mousemove', onPointerMove, false);
        container.addEventListener('mouseup', onPointerUp, false);
        container.addEventListener('wheel', onDocumentMouseWheel, false);
        container.addEventListener('touchstart', onPointerStart, false);
        container.addEventListener('touchmove', onPointerMove, false);
        container.addEventListener('touchend', onPointerUp, false);
        window.addEventListener('resize', onWindowResize, false);
    }
    function onWindowResize() {
        containerWidth = container.parentNode.clientWidth
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(containerWidth, containerWidth / 16 * 9);
    }
    function onPointerStart(event) {
        isUserInteracting = true;
        var clientX = event.clientX || event.touches[0].clientX;
        var clientY = event.clientY || event.touches[0].clientY;
        onMouseDownMouseX = clientX;
        onMouseDownMouseY = clientY;
        onMouseDownLon = lon;
        onMouseDownLat = lat;
    }
    function onPointerMove(event) {
        if (isUserInteracting === true) {
            var clientX = event.clientX || event.touches[0].clientX;
            var clientY = event.clientY || event.touches[0].clientY;
            lon = (onMouseDownMouseX - clientX) * 0.1 + onMouseDownLon;
            lat = (clientY - onMouseDownMouseY) * 0.1 + onMouseDownLat;
        }
    }
    function onPointerUp() {
        isUserInteracting = false;
    }
    function onDocumentMouseWheel(event) {
        event.preventDefault()
        var fov = camera.fov + event.deltaY * 0.05;
        camera.fov = THREE.Math.clamp(fov, 10, 75);
        camera.updateProjectionMatrix();
    }
    function animate() {
        requestAnimationFrame(animate);
        update();
    }
    function update() {
        if (isUserInteracting === false) {
            lon += 0.1;
        }
        lat = Math.max(- 85, Math.min(85, lat));
        phi = THREE.Math.degToRad(90 - lat);
        theta = THREE.Math.degToRad(lon);
        camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
        camera.target.y = 500 * Math.cos(phi);
        camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);
        camera.lookAt(camera.target);
        renderer.render(scene, camera);
    }
}



const Panorama = (props) => {
    useEffect(() => {
        showPanorama(props.width, props.image, props.containerId)
        const pano = document.getElementById(props.containerId)
        var panoContainerWidth = pano.parentNode.clientWidth
        pano.setAttribute('height', panoContainerWidth * 9/16)

        // Аналог метода componentWillUnmount
        return () => {
            document.getElementById(props.containerId).innerHTML = null
        }  
    })

    return (
        <div id={props.containerId} style={{ cursor: 'pointer' }}></div>

    )
}

export default Panorama;