import React, { Component } from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import image4 from '../static/images/4.jpg'
import image5 from '../static/images/5.jpg'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            father: false,
            son: false
        }
    }

    render() {
        const containerStyle = {
            marginTop: '30px'
        }

        const { father, son } = this.state

        const openCloseFather = () => {
            this.setState({ 
                father: !this.state.father
            })
        }

        const openCloseSon = () => {
            this.setState({ 
                son: !this.state.son
            })
        }

        const about = {
            father: {
                name: "Евгений Ингерман",
                position: "Инженер-системотехник АСУ",
                description: "Сертифицирован ABB, Bosch, Geberit, KBE, Knauf, Legrand, Litokol, Rehau, Saint Gobain, Tarkett, Tikkurila, Uponor, Xella"
            },
            son: {
                name: "Антон Ингерман",
                position: "Специалист по прототипированию информационных систем",
                description: "Сертифицирован ABB, Bosch, Knauf, Legrand, Litokol, Rehau, Tarkett, Tikkurila, Uponor, Xella, Яндекс/МФТИ"
            }
        }

        return (
            <Layout>
                <Container style={containerStyle}>
                    <h2 >АВТОРЫ ПРОЕКТА</h2>
                    <Row style={containerStyle}>
                        <Col md={6} xs={12} className="text-center">
                            <Image src={image4} rounded style={{ width: "75%", cursor: 'pointer' }} alt="4.jpg" onClick={openCloseFather}></Image>
                            <h3>Евгений Ингерман</h3>
                        </Col>
                        <Col md={6} xs={12} className="text-center align-self-end">
                            <Image src={image5} rounded style={{ width: "75%", cursor: 'pointer' }} alt="5.jpg" onClick={openCloseSon}></Image>
                            <h3>Антон Ингерман</h3>
                        </Col>
                    </Row>
                </Container>

                <Modal centered show={father} onHide={openCloseFather} size="md">
                    <Modal.Body>
                        <Card.Img src={image4} variant="top"></Card.Img>
                        <h1>{about.father.name}</h1>
                        <h4>{about.father.position}</h4>
                        <p>{about.father.description}</p>
                    </Modal.Body>
                </Modal>
                <Modal centered show={son} onHide={openCloseSon} size="md">
                    <Modal.Body>
                        <Card.Img src={image5} variant="top"></Card.Img>
                        <h1 style={{marginTop: '20px'}}>{about.son.name}</h1>
                        <h4>{about.son.position}</h4>
                        <p style={{marginTop: '20px'}}>{about.son.description}</p>
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}

export default About