import React from "react"
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MyCard from '../components/mycard'
import Preview from '../components/preview'
import Panorama from "../components/panorama"
import Workshop from '../components/workshop'
import YouTube from '../components/youtube'
import image360 from '../static/images/panorama3.png';
import { cards1, cards2, cards3, cards4, sectionTitles, miniCards1, miniCards2, miniCards3, videos } from '../data/data'

const Index = () => {
    // const blockScrollPage = () => {
    //     document.body.style.overflow = 'hidden'
    // }
    // const unblockScrollPage = () => {
    //     document.body.style.overflow = ''
    // }

    const headerStyle = {
        textTransform: 'none',
        textAlign: 'center',
        marginBottom: '25px'
    }

    const containerStyle = {
        marginTop: '30px'
    }

    const rowOfCards1 = cards1.map((card, index) => {
        return (
            <Col key={index} md={4} xs={12} className="align-self-end">
                <MyCard key={index} content={card.image} header={card.header} title={card.title} text={card.text} button={card.button} more={card.more} tooltipTitle={card.tooltipTitle} tooltipContent={card.tooltipContent}>
                </MyCard>
            </Col>
        )
    })
    const rowOfCards2 = cards2.map((card, index) => {
        return (
            <Col key={index} md={4} xs={12} className="align-self-end">
                <MyCard key={index} content={card.image} header={card.header} title={card.title} text={card.text} button={card.button} tooltipTitle={card.tooltipTitle} tooltipContent={card.tooltipContent}></MyCard>
            </Col>
        )
    })
    const rowOfCards3 = cards3.map((card, index) => {
        return (
            <Col key={index} md={4} xs={12} className="align-self-end">
                <MyCard key={index} content={card.image} header={card.header} title={card.title} text={card.text} button={card.button} tooltipTitle={card.tooltipTitle} tooltipContent={card.tooltipContent}></MyCard>
            </Col>
        )
    })
    const rowOfCards4 = cards4.map((card, index) => {
        return (
            <Col key={index} md={4} xs={12} className="align-self-end">
                <MyCard key={index} content={card.image} header={card.header} title={card.title} text={card.text} button={card.button} tooltipTitle={card.tooltipTitle} tooltipContent={card.tooltipContent}>
                </MyCard>
            </Col>
        )
    })
    const rowOfMiniCards1 = miniCards1.map((card, index) => {
        return (
            <Col key={index} md={4}>
                <div style={{ textAlign: "center" }} key={index}>
                    <img src={card.image} alt="reactlogo" style={{ width: '64px' }}></img>
                    <p>{card.subtitle}</p>
                </div>
            </Col>
        )
    })
    const rowOfMiniCards2 = miniCards2.map((card, index) => {
        return (
            <Col key={index} md={4}>
                <div style={{ textAlign: "center" }} key={index}>
                    <img src={card.image} alt="reactlogo" style={{ width: '64px' }}></img>
                    <p>{card.subtitle}</p>
                </div>
            </Col>
        )
    })
    const rowOfMiniCards3 = miniCards3.map((card, index) => {
        return (
            <Col key={index} md={4}>
                <div style={{ textAlign: "center" }}>
                    <img src={card.image} alt={card.alt} style={{ width: '64px' }}></img>
                    <h4>{card.title}</h4>
                    <p>{card.subtitle}</p>
                </div>
            </Col>
        )
    })

    return (
        <Layout>
            <Preview></Preview>
            <Container style={containerStyle}>
                <h2 style={headerStyle}>{sectionTitles.title4}</h2>
                <Row>
                    <Col key="testcardcol3" md={4} xs={12} className="align-self-end">
                        <MyCard type="video" key="testcard3" header="ВИДЕО" title="Интерьер ванной" text="1 минута видео Full HD: 8 часов">
                            <YouTube disabled="true" id="videotest" link="https://www.youtube.com/embed/pkpZawD--88"></YouTube>
                        </MyCard>
                    </Col>
                    <Col key="testcardcol4" md={4} xs={12} className="align-self-end">
                        <MyCard type="workshop" key="testcard4" header="ВИТРИНА" title="ИКЕА Валламоссе" text="1 товар: 10 минут">
                            <Workshop containerId="canvastest"></Workshop>
                        </MyCard>
                    </Col>
                    <Col key="testcardcol2" md={4} xs={12} className="align-self-end">
                        <MyCard type="panorama" key="testcard2" header="ПАНОРАМЫ" title="Интерьер ванной" text="1 панорама Full HD: 20 минут">
                            <Panorama image={image360} containerId="panoramatest"></Panorama>
                        </MyCard>
                    </Col>
                    {rowOfCards4}
                </Row>
            </Container>
            <Container style={containerStyle}>
                <h2 style={headerStyle}>{sectionTitles.title1}</h2>
                <Row>{rowOfCards1}</Row>
            </Container>
            <Container style={containerStyle}>
                <h2 style={headerStyle}>{sectionTitles.title2}</h2>
                <Row>{rowOfCards2}</Row>
            </Container>
            <Container style={containerStyle}>
                <h2 style={headerStyle}>{sectionTitles.title3}</h2>
                <Row>{rowOfCards3}</Row>
            </Container>

            <Container>
                <Row>
                    <Col md={12} style={{ marginTop: '50px', marginBottom: '50px' }}>
                        <YouTube id={videos.video1.id} width="100%" height="auto" link={videos.video1.link}></YouTube>
                    </Col>
                </Row>
            </Container>
            {/* <Container onMouseOver={blockScrollPage} onFocus={blockScrollPage} onMouseOut={unblockScrollPage} onBlur={unblockScrollPage} onTouchStart={blockScrollPage} onTouchEnd={unblockScrollPage} >
                <Row>
                    <Col md={12}>
                        <Panorama image={image360} containerId="panorama"></Panorama>
                    </Col>
                </Row>
            </Container> */}
            {/* <Container>
                <Row>
                    <Col md={12} style={{ marginTop: '50px', marginBottom: '50px' }}>
                        <YouTube id={videos.video2.id} width="100%" height="auto" link={videos.video2.link}></YouTube>
                    </Col>
                </Row>
            </Container> */}

            <Container>
                <h2 style={headerStyle}>{sectionTitles.title5}</h2>
                <Row>{rowOfMiniCards1}</Row>
            </Container>
            <br />
            <Container>
                <h2 style={headerStyle}>{sectionTitles.title6}</h2>
                <Row>{rowOfMiniCards2}</Row>
            </Container>
            <br />
            <Container>
                <h2 style={headerStyle}>{sectionTitles.title7}</h2>
                <Row>{rowOfMiniCards3}</Row>
            </Container>
        </Layout >
    )
}

export default Index