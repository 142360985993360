import React, { useEffect } from 'react'
import * as THREE from 'three';
// GUI модуль не дает сформировать build-версию по причине подгрузки объекта window до полной загрузки каркаса
// import { GUI } from '../../node_modules/three/examples/jsm/libs/dat.gui.module';
import { OrbitControls } from '../../node_modules/three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from '../../node_modules/three/examples/jsm/loaders/FBXLoader'
// import pathtofont from '../../node_modules/three/examples/fonts/helvetiker_regular.typeface.json'
import kran from '../flat.fbx'
import '../components/kran.css'


const Flat = () => {
    const style = {
        width: '100%',
        height: "100vh",
    }

    useEffect(() => {
        // var params = {
        //     roughness: 0.4,
        //     metalness: 0.5,
        //     exposure: 0.57,
        //     color: 0xFFFFFF
        // };

        var camera, scene

        function init() {

            // Вспомогательные функции
            // Возврат цвета
            // class ColorGUIHelper {
            //     constructor(object, prop) {
            //         this.object = object;
            //         this.prop = prop;
            //     }
            //     get value() {
            //         return `#${this.object[this.prop].getHexString()}`;
            //     }
            //     set value(hexString) {
            //         this.object[this.prop].set(hexString);
            //     }
            // }

            // var gui = new GUI();
            // gui.domElement.id = 'gui'

            const canvas = document.querySelector('#c');
            const renderer = new THREE.WebGLRenderer({ canvas });
            renderer.shadowMap.enabled = true


            camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
            camera.position.set(0, 30, 30);
            scene = new THREE.Scene();


            // Освещение
            const SpotLight = options => {
                const params = {
                    x: options.x,
                    y: options.y,
                    z: options.z,
                    xtarget: options.xtarget,
                    ytarget: options.ytarget,
                    ztarget: options.ztarget,
                    color: options.color,
                    intensity: options.intensity || 1,
                    distance: options.distance,
                    angle: options.angle
                }

                var light = new THREE.SpotLight(params.color, params.intensity, params.distance, Math.cos(params.angle * Math.PI / 180))
                // light.scale.x = 30
                // light.scale.y = 30
                // light.scale.z = 30
                // console.log(light)
                // const lightHelper = new THREE.SpotLightHelper(light)


                const setupLight = () => {
                    light.angle = params.angle
                    light.position.set(params.x, params.y, params.z)
                    light.target.position.set(params.xtarget, params.ytarget, params.ztarget)
                    light.target.updateMatrixWorld();
                    light.castShadow = true
                    light.recieveShadow = true
                }

                setupLight()

                // gui.add(params, 'x', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'y', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'z', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'xtarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'ytarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'ztarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'angle', 0, 4).onChange(() => setupLight())
                // gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
                // gui.add(params, 'intensity', 0, 100)


                scene.add(light)
                scene.add(light.target);
                // scene.add(lightHelper);
                return light
            }
            SpotLight({ x: 0, y: 20, z: 0, xtarget: -1, ytarget: -100, ztarget: 1, color: 0xFFFFFF, intensity: 1, angle: Math.PI/ 2 })
            // const myLight = SpotLight({ x: -1, y: 18, z: -12, xtarget: -1, ytarget: -100, ztarget: 1, color: 0xFFFFFF, intensity: 1, angle: Math.PI/ 12 }) // Освещение кухня
            // const myLight2 = SpotLight({ x: 0, y: 10, z: 5, xtarget: 0, ytarget: 0, ztarget: 0, color: 0xFFFFFF, intensity: 1, angle: Math.PI / 6 }) // Освещение спальня
            // const myLight3 = SpotLight({ x: 14, y: 10, z: -10, xtarget: 0, ytarget: -30, ztarget: -14, color: 0xFFFFFF, intensity: 0.3, angle: Math.PI / 12 }) // Освещение спальня
            // const myLight4 = SpotLight({ x: 14, y: 10, z: -5, xtarget: 0, ytarget: -30, ztarget: -14, color: 0xFFFFFF, intensity: 0.3, angle: Math.PI / 12 }) // Освещение спальня
            // const myLight4 = SpotLight({ x: -0.7, y: 18, z: -5, xtarget: -1, ytarget: -100, ztarget: 1, color: 0xFFFFFF, intensity: 1, angle: Math.PI / 12 }) // Освещение спальня


            // Общий свет
            // const color = 0xFFFFFF;
            // const intensity = 1;
            // const ambientLight = new THREE.AmbientLight(color, intensity);
            // scene.add(ambientLight);
            // gui.addColor(new ColorGUIHelper(ambientLight, 'color'), 'value').name('ambientLight');
            // gui.add(ambientLight, 'intensity', 0, 2, 0.01);

            // Стены
            // Описание создания куба
            const Cube = options => {
                var boxWidth = options.width | 1,
                    boxHeight = options.height | 1,
                    boxDepth = options.depth | 1,
                    boxColor = options.color,
                    x = options.x | 0,
                    y = options.y | 0,
                    z = options.z | 0
                const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
                const wallmaterial = new THREE.MeshPhongMaterial({ color: boxColor });
                const cube = new THREE.Mesh(geometry, wallmaterial);
                cube.receiveShadow = true
                cube.castShadow = true
                cube.position.y = y
                cube.position.x = x
                cube.position.z = z
                return cube
            }

            const offset = -25

            // const params2 = {
            //     x: 0,
            //     y: 10,
            //     z: 0
            // }
            const backWall = Cube({ color: 0xff0000, x: 0, y: 15, z: offset, width: 1, height: 50, depth: 50 })
            const frontWall = Cube({ color: 0xffffff, x: 0, y: 15, z: 25, width: 1, height: 50, depth: 50 })
            const floor = Cube({ color: 0xffffff, x: 0, y: -10, z: 0, width: 1, height: 50, depth: 48 })
            // const cube = Cube({ color: 0x0000ff, x: params2.x, y: params2.y, z: params2.z, width: 10, height: 10, depth: 10 })
            backWall.rotation.y = Math.PI / 2
            frontWall.rotation.y = Math.PI / 2
            floor.rotation.z = Math.PI / 2
            // scene.add(backWall)
            // scene.add(floor)
            // scene.add(cube)


            const fbxLoader = new FBXLoader();
            fbxLoader.load(kran, (model) => {
                console.log(model)
                const scale = 1
                model.scale.x = scale
                model.scale.y = scale
                model.scale.z = scale
                model.position.x = -40
                model.position.y = 0
                model.position.z = 20
                model.rotation.y = Math.PI
                model.rotation.x = Math.PI / 2
                model.children.forEach((mesh) => {
                    if (mesh instanceof THREE.Mesh) {
                        console.log(mesh.name)
                        mesh.recieveShadow = true
                        mesh.castShadow = true
                        mesh.material = new THREE.MeshStandardMaterial({
                            // emissive: 0x111111,
                            color: 0xCCCCCC,    // red (can also use a CSS color string here)
                            flatShading: true,
                            // envMapIntensity: 2.0
                        });
                        mesh.material.needsUpdate = true
                    }
                    if (mesh.name === "ИКЕА_Семейство_шкафов") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0xfdf4e3,
                            flatShading: true,
                        });
                        mesh.material.needsUpdate = true
                        // mesh.material = 0
                    }
                    if (mesh.name === "ИКЕА_столешница") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0xeeeeee,
                            flatShading: true,
                        });
                        mesh.material.needsUpdate = true  
                    }
                    if (mesh.name === "Обобщенные_модели_1") {
                        // mesh.material = 0    
                    }
                    if (mesh.name === "Перекрытия") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0x777777,
                            flatShading: true,
                        });   
                    }
                    if (mesh.name === "Стены") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0xFFFFFF,
                            flatShading: true,
                        });   
                    }
                    if (mesh.name === "Дверь-Входная-стандарт-панель") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0x000000,
                            flatShading: true,
                        });   
                    }
                    if (mesh.name === "Дверное_полотно_3D_глухое") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0xFFFFFF,
                            flatShading: true,
                        });   
                    }
                    if (mesh.name === "окнаrehau_однопольное") {
                        mesh.material = new THREE.MeshStandardMaterial({
                            color: 0xFFFFFF,
                            opacity: 0.1,
                            flatShading: true,
                        });   
                    }
                    })
                scene.add(model);
            });


            renderer.setSize(window.innerWidth, window.innerHeight, false)
            renderer.render(scene, camera);

            // Управление навигацией
            const controls = new OrbitControls(camera, canvas);
            controls.target.set(0, 0, 0);
            controls.update();

            const animate = () => {
                // ЗАЛПАТКА: перерисовка холста при изменении размеров окна
                if (resizeRendererToDisplaySize(renderer)) {
                    const canvas = renderer.domElement;
                    camera.aspect = canvas.clientWidth / canvas.clientHeight;
                    camera.updateProjectionMatrix();
                }
                scene.rotation.y -= 0.001
                renderer.render(scene, camera)
                requestAnimationFrame(animate)
                camera.updateProjectionMatrix();
            }
            requestAnimationFrame(animate)

            // Изменение размера сцены при изменении размеров окна
            const resizeRendererToDisplaySize = renderer => {
                const canvas = renderer.domElement;
                const pixelRatio = window.devicePixelRatio;
                const width = canvas.clientWidth * pixelRatio | 0;
                const height = canvas.clientHeight * pixelRatio | 0;
                const needResize = canvas.width !== width || canvas.height !== height;
                if (needResize) {
                    renderer.setSize(width, height, false);
                }
                return needResize;
            }
            return scene

        }

        const lazy = (scene) => {
            var params = {
                // roughness: 0.57,
                // metalness: 0.96,
                color: 0xFFFFFF,
            };

            // var gui = new GUI();

            const update = () => {
                scene.children.forEach((child) => {
                    if (child instanceof THREE.Group) {
                        const mesh = child
                        mesh.children.forEach((element) => {
                            if (element instanceof THREE.Mesh) {
                                element.material.metalness = params.metalness
                                element.material.roughness = params.roughness
                            }
                        })
                    }
                })
            }

            params.update = update
            // gui.add(params, 'metalness', 0, 1, 0.01).onChange(() => update());
            // gui.add(params, 'roughness', 0, 1, 0.01).onChange(() => update());

        }

        const myScene = init();
        setTimeout(lazy, 1000, myScene)
    })

    return (
        <>
            <canvas id="c" style={style}></canvas>
        </>
    )

}

export default Flat