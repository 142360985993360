import React from "react"
import YandexMetrika from './yandexMetrika'
import TitleBlock from './titleBlock'
import Footer from './footer'
// import Navpanel from './navpanel'

const Layout = ({children}) => {
    return (
        <div id="layout">
            <YandexMetrika></YandexMetrika>
            <TitleBlock/>
            {/* <Navpanel></Navpanel> */}
            <div id="content">
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
