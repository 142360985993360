import React, {useEffect} from 'react'
import * as THREE from 'three';
import { OrbitControls } from '../../node_modules/three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from '../../node_modules/three/examples/jsm/loaders/FBXLoader'
import kran from '../kran.fbx'

const Workshop = (props) => {
    useEffect(() => {


        var params = {
            roughness: 0.4,
            metalness: 0.96,
            exposure: 0.57,
            color: 0xFFFFFF
        };
    
        var camera, scene

        function init() {

            // Вспомогательные функции
            // Возврат цвета
            // class ColorGUIHelper {
            //     constructor(object, prop) {
            //         this.object = object;
            //         this.prop = prop;
            //     }
            //     get value() {
            //         return `#${this.object[this.prop].getHexString()}`;
            //     }
            //     set value(hexString) {
            //         this.object[this.prop].set(hexString);
            //     }
            // }
    
            const canvas = document.getElementById(props.containerId);
            var canvasParentWidth = canvas.parentNode.clientWidth
            if (canvas.parentNode.className === "modal-body" || canvas.parentNode.className === "col-md-12") {
                canvasParentWidth = canvas.parentNode.clientWidth - 32
            }
            const renderer = new THREE.WebGLRenderer({ canvas });
            renderer.shadowMap.enabled = true
            
            camera = new THREE.PerspectiveCamera(30, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);

            camera.position.set(0, 85, 75);
            // gui.add(cameraParams, 'x', -100, 100, 1);
            scene = new THREE.Scene();
        
    
            // Освещение
            const SpotLight = options => {
                const params = {
                    x: options.x,
                    y: options.y,
                    z: options.z,
                    xtarget: options.xtarget,
                    ytarget: options.ytarget,
                    ztarget: options.ztarget,
                    color: options.color,
                    intensity: options.intensity || 1,
                    distance: options.distance,
                    angle: options.angle
                }
    
                var light = new THREE.SpotLight(params.color, params.intensity, params.distance, Math.cos(params.angle * Math.PI/180))
                // light.scale.x = 30
                // light.scale.y = 30
                // light.scale.z = 30
                // console.log(light)
                // const lightHelper = new THREE.SpotLightHelper(light)
                
    
                const setupLight = () => {
                    light.angle = params.angle
                    light.position.set(params.x, params.y, params.z)
                    light.target.position.set(params.xtarget, params.ytarget, params.ztarget)
                    light.target.updateMatrixWorld();
                    light.castShadow = true
                    light.recieveShadow = true
                }
    
                setupLight()
    
                // gui.add(params, 'x', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'y', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'z', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'xtarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'ytarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'ztarget', -100, 100).onChange(() => setupLight())
                // gui.add(params, 'angle', 0, 4).onChange(() => setupLight())
                // gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
                // gui.add(params, 'intensity', 0, 100)
                
                
                scene.add(light)
                scene.add(light.target);
                // scene.add(lightHelper);
                return light
            }
            SpotLight({ x: 1, y: 82, z: 100, xtarget: 0,ytarget: 38, ztarget: 34, color: 0xFFFFFF, intensity: 2, angle: Math.PI/12 }) // Освещение сверху
            // DirectLight({ x: 0, y: -5, z: 0, color: 0xFFFFFF, intensity: 1 }) // Освещение сверху
    
    
            // Общий свет
            // const color = 0xFFFFFF;
            // const intensity = 1;
            // const ambientLight = new THREE.AmbientLight(color, intensity);
            // scene.add(ambientLight);
            // gui.addColor(new ColorGUIHelper(ambientLight, 'color'), 'value').name('ambientLight');
            // gui.add(ambientLight, 'intensity', 0, 2, 0.01);
    
            // Стены
            // Описание создания куба
            const Cube = options => {
                var boxWidth = options.width | 1,
                    boxHeight = options.height | 1,
                    boxDepth = options.depth | 1,
                    boxColor = options.color,
                    x = options.x | 0,
                    y = options.y | 0,
                    z = options.z | 0
                const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
                const wallmaterial = new THREE.MeshPhongMaterial({ color: boxColor });
                const cube = new THREE.Mesh(geometry, wallmaterial);
                cube.receiveShadow = true
                cube.castShadow = true
                cube.position.y = y
                cube.position.x = x
                cube.position.z = z
                return cube
            }
    
            const offset = -25
    
            // const params2 = {
            //     x: 0,
            //     y: 10,
            //     z: 0
            // }
            const backWall = Cube({ color: 0xff0000, x: 0, y: 15, z: offset, width: 1, height: 50, depth: 50 })
            const frontWall = Cube({ color: 0xffffff, x: 0, y: 15, z: 25, width: 1, height: 50, depth: 50 })
            const floor = Cube({ color: 0xffffff, x: 0, y: -10, z: 0, width: 1, height: 50, depth: 48 })
            // const cube = Cube({ color: 0x0000ff, x: params2.x, y: params2.y, z: params2.z, width: 10, height: 10, depth: 10 })
            backWall.rotation.y = Math.PI / 2
            frontWall.rotation.y = Math.PI / 2
            floor.rotation.z = Math.PI / 2
            scene.add(backWall)
            scene.add(floor)
            // scene.add(cube)
        
            const fbxLoader = new FBXLoader();
            fbxLoader.load(kran, (model) => {
                const scale = 50
                model.scale.x = scale
                model.scale.y = scale
                model.scale.z = scale
                model.position.x = 20
                model.position.y = -250
                model.position.z = offset -12
                model.rotation.y = Math.PI
                model.rotation.x = Math.PI / 2
                model.children.forEach((mesh) => {
                    if (mesh instanceof THREE.Mesh) {
                        mesh.recieveShadow = true
                        mesh.castShadow = true
                        mesh.material = new THREE.MeshStandardMaterial({
                            metalness: params.metalness,
                            roughness: params.roughness,
                        });
                        mesh.material.needsUpdate = true
                    }
                })
                
                scene.add(model);
            });
    
    
            renderer.setSize(canvasParentWidth, canvasParentWidth * 9/16, false)
            renderer.render(scene, camera);
    
            // Управление навигацией
            const controls = new OrbitControls(camera, canvas);
            controls.target.set(0, 0, 0);
            controls.update();
    
            const animate = () => {
                // ЗАЛПАТКА: перерисовка холста при изменении размеров окна
                if (resizeRendererToDisplaySize(renderer)) {
                    const canvas = renderer.domElement;
                    camera.aspect = canvas.clientWidth / canvas.clientHeight;
                    camera.updateProjectionMatrix();
                }
                scene.rotation.y -= 0.001
                renderer.render(scene, camera)
                requestAnimationFrame(animate)
            }
            requestAnimationFrame(animate)
    
            // Изменение размера сцены при изменении размеров окна
            const resizeRendererToDisplaySize = renderer => {
                const canvas = renderer.domElement;
                const width = canvas.clientWidth;
                const height = canvas.clientHeight;
                const needResize = canvas.width !== width || canvas.height !== height;
                if (needResize) {
                    renderer.setSize(width, height, false);
                }
                return needResize;
            }

            const myresize = () => {
                canvasParentWidth = canvas.parentNode.clientWidth
                renderer.setSize(canvasParentWidth, canvasParentWidth * 9/16, false)
            }

            window.addEventListener('resize', myresize)
            return scene
           
    
        }
    
        const lazy = (scene) => {
            var params = {
                // roughness: 0.57,
                // metalness: 0.96,
                color: 0xFFFFFF,
            };
    
            // var gui = new GUI();
    
            const update = () => {
                scene.children.forEach((child) => {
                    if (child instanceof THREE.Group) {
                        const mesh = child
                        mesh.children.forEach((element) => {
                            if (element instanceof THREE.Mesh) {
                                element.material.metalness = params.metalness
                                element.material.roughness = params.roughness
                            }
                        })
                    }
                })
            }
    
            params.update = update
            // gui.add(params, 'metalness', 0, 1, 0.01).onChange(() => update());
            // gui.add(params, 'roughness', 0, 1, 0.01).onChange(() => update());
    
        }

        const myScene = init();
        setTimeout(lazy, 1000, myScene)

        
    })
    

    return (
        <>
            <canvas id={props.containerId} style={{ cursor: 'pointer' }}></canvas>
        </>
    )
}

export default Workshop