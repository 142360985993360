import React from 'react';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../static/images/logo1.png'
import { Link } from "react-router-dom"

const TitleBlock = () => {
    return (
        <div id="titleBlock">
        <Container fluid className="bg-dark text-white p-4">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={4} style={{ display: 'flex', justifyContent: "center" }}>
                     <Link to="/">
                        <Image src={logo} style={{ width: "168px" }}></Image>
                     </Link>
                    </Col>
                </Row>
            </Container>
        </Container>
        </div>
    )
}

export default TitleBlock;