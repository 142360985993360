import React, { useEffect } from 'react'
import './youtube.css'

const YouTube = (props) => {
    useEffect(() => {
        resize()
        checkDisabled()
        window.addEventListener('resize', resize)

    })



    const resize = () => {
        setTimeout(
            function () {
                const videoContainer = document.getElementById(props.id)
                const parentContainer = videoContainer.parentNode.className
                var width = null
                if (parentContainer === "modal-body" || parentContainer === "col-md-12") {
                    width = videoContainer.parentNode.clientWidth - 32
                } else {
                    width = videoContainer.parentNode.clientWidth
                }
                const height = width * 9 / 16
                videoContainer.setAttribute('width', width)
                videoContainer.setAttribute('height', height)
            }, 10
        )
    }

    const checkDisabled = () => {
        const videoContainer = document.getElementById(props.id)
        if (props.disabled) {
            videoContainer.classList.add('ytplayer')
        }
    }

    return (
        <>
            <iframe id={props.id} width="100%" height="auto" src={props.link} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="video"></iframe>
        </>
    )
}

export default YouTube