import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'
// import Popover from 'react-bootstrap/Popover'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Modal from 'react-bootstrap/Modal'
import './preview.css'

// const popover = (
//     <Popover id="popover-basic">
//         <Popover.Title as="h3">BIM</Popover.Title>
//         <Popover.Content>
//             <i>Building Information Modeling</i> - подход к возведению, оснащению, эксплуатации и ремонту (а также сносу) здания (к управлению жизненным циклом объекта), который предполагает сбор и комплексную обработку в процессе проектирования всей архитектурно-конструкторской, технологической, экономической и иной информации о здании со всеми её взаимосвязями и зависимостями, когда здание и всё, что имеет к нему отношение, рассматриваются как единый объект.
//       </Popover.Content>
//     </Popover>
// );

const Preview = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
        <Container fluid className="bg-dark" style={{ display: "flex", alignItems: "center" }}>
            <Container style={{ display: 'flex', alignItems: "center", color: "#FFFFFF", flexDirection: "column", marginBottom: '50px' }}>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                            <h1 className="display-4 title">Информационное моделирование объектов, работ и материалов</h1>
                        {/* <OverlayTrigger trigger="hover" placement="bottom-end" overlay={popover}>
                            <p className="subtitle" style={{ fontSize: "24px", marginTop: '15px' }}>с ипользованием <strong>BIM-технологий</strong></p>
                        </OverlayTrigger> */}
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={4}>
                        <Button style={{ marginTop: '25px', marginBottom: "50px" }} variant="light" onClick={handleShow}><strong>Подробнее</strong></Button>
                    </Col>
                </Row> */}
            </Container>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <h3 style={{ paddingBottom: "30px" }}>Мы разрабатываем автоматические сервисы для того, чтобы:</h3>
                    <ol>
                        <li>Дизайнерские, проектные и сметные услуги, строительные и ремонтные  работы производились значительно прозрачнее, точнее и быстрее, чем сейчас</li>
                        <li>Стоимость таких услуг и работ была для массового пользователя существенно ниже</li>
                        <li>Профессионалы зарабатывали больше</li>
                    </ol>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Preview