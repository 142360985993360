import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
    return (
        <div id="footer">
            <Container fluid className="p-3 p-md-5 bg-dark text-white">
                <Row>
                    <Col>
                        <p style={{ textAlign: 'center' }}>Евгений Ингерман | Антон Ингерман</p>
                        <p style={{ textAlign: 'center' }}>Сертифицированы ABB, Bosch, Geberit, KBE, Knauf, Legrand, Litokol, Rehau, Saint-Gobain, Tarkett, Tikkurila, Uponor, Xella</p>
                        <address><p style={{ textAlign: 'center' }}>Москва, Нахимовский проспект, 24, стр. 1</p></address>
                        <p style={{ textAlign: 'center' }}>info@ingerman.pro</p>
                        {/* <p style={{ textAlign: 'center' }}>Вы зашли с {navigator.platform}</p> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;