import React, { useEffect } from 'react'

const TaskCalculator2 = () => {
    var widgetOptions297820 = {
        bg_color: "transparent"
    };
    useEffect(() => {
        (function () {
            var a = document.createElement("script"), h = "head";
            a.async = true;
            a.src = (document.location.protocol == "https:" ? "https:" : "http:") + "//ucalc.pro/api/widget.js?id=297820&t=" + Math.floor(new Date() / 18e5);
            document.getElementsByTagName(h)[0].appendChild(a)
        })();
    });


    return (
        <>
            <div class="uCalc_297820"></div>
        </>
    )
}

export default TaskCalculator2