import React from "react"
import itermicImage from '../static/images/itermic.jpg'
import fixImage from '../static/images/fix1.jpg'
import furnitureImage from '../static/images/furniture.jpg'
import ikeaImage from '../static/images/ikea.jpg'
import planImage from '../static/images/plan.png'
import processImage from '../static/images/process.jpg'
import worksImage from '../static/images/works.jpg'
import materialsImage from '../static/images/materials.jpg'
import toolsImage from '../static/images/tools.jpg'
// import videoImage from '../static/images/video.png'
import renderImage from '../static/images/render.png'
import motionImage from '../static/images/motion.png'
import reactlogo from '../static/images/react1.png'
import htmllogo from '../static/images/html.png'
import smartphonelogo from '../static/images/smartphone.png'
import scalelogo from '../static/images/scale.png'
import worldlogo from '../static/images/world.png'
import stockslogo from '../static/images/stocks.png'
import brickslogo from '../static/images/bricks.png'
import codelogo from '../static/images/code.png'
import httpslogo from '../static/images/https.png'
import meplogo from '../static/images/MEP.png'
import technologylogo from '../static/images/technology.png'


const section1Title = "МОДЕЛИРУЕМ"
const section2Title = "РАЗРАБАТЫВАЕМ"
const section3Title = "РАССЧИТЫВАЕМ"
const section4Title = "ВИЗУАЛИЗИРУЕМ"
const section5Title = "ТАКЖЕ"
const section6Title = "УМЕЕМ"
const section7Title = "ПРИГЛАШАЕМ ПАРТНЕРОВ ДЛЯ ЗАПУСКА BIM-СЕРВИСА"

export const sectionTitles = {
    title1: section1Title,
    title2: section2Title,
    title3: section3Title,
    title4: section4Title,
    title5: section5Title,
    title6: section6Title,
    title7: section7Title,
}

// const card1TooltipTitle = () => {
//     return <h1>Разметка</h1>
// }

const card1TooltipContent = () => {
    return (
        <ul style={{listStyleType: 'none'}}>
            <li>
                <span>МЕТОД/МАКСИМЕРА </span>
                <br />
                <span>АСКЕРСУНД 4 ЯЩИКА</span>
                <br />
                <span><b>12 100 ₽</b></span>
            </li>
            <br />
            <li>
                <span>МЕТОД</span>
                <br />
                <span>АСКЕРСУНД 2 двери</span>
                <br />
                <span><b>5 200 ₽</b></span>
            </li>
        </ul>
    )
}

// const card1more = () => {
//     return (
//         <></>
//     )
// }

const card1 = {
    header: "МЕБЕЛЬ И ДЕКОР",
    image: furnitureImage,
    title: "Кухня ИКЕА МЕТОД",
    text: "Все шкафы всех серий: 24 часа",
    button: "Подробнее",
    more: "",
    tooltipContent: card1TooltipContent
}

const card2TooltipContent = () => {
    return (
        <ul>
            <li style={{listStyleType: 'none'}}>
                <span>Напольный конвектор ITERMIC ITT.110.200.1000</span>
                <br />
                <span><b>9250 ₽</b></span>
            </li>
        </ul>
    )
}

const card2 = {
    header: "ОБОРУДОВАНИЕ",
    image: itermicImage,
    title: "Конвекторы itermic",
    text: "234 типоразмера: 4 часа",
    button: "Подробнее",
    more: "",
    // tooltipTitle: "Напольный конвектор ITERMIC ITT.110.200.1000",
    tooltipContent: card2TooltipContent
}

const card3TooltipContent = () => {
    return (
        <ul style={{listStyleType: 'none'}}>
            <li>
                <span>Дюбель-гвоздь</span>
                <br />
                <span>"СТРОЙБАТ" 6 x 40мм</span>
                <br />
                <span><b>1 ₽</b></span>
            </li>
            <br />
            <li>
                <span>Гибкая связь</span>
                <br />
                <span>BEVER MV 300-5</span>
                <br />
                <span><b>23 ₽</b></span>
            </li>
        </ul>
    )
}

const card3 = {
    header: "МАТЕРИАЛЫ",
    image: fixImage,
    title: "Крепёж перегородок",
    text: "Соединительный узел: 2 часа",
    button: "Подробнее",
    more: "",
    tooltipTitle: "",
    tooltipContent: card3TooltipContent
}

const card4TooltipContent = () => {
    return (
        <ul>
            <li style={{listStyleType: 'none'}}>
                <span>Кухня МЕТОД АСКЕРСУНД</span>
                <br />
                <span><b>63 705 ₽</b></span>
            </li>
        </ul>
    )
}

const card4 = {
    header: "ДИЗАЙН ИНТЕРЬЕРА",
    image: ikeaImage,
    title: "3D-модель помещения",
    text: "29 объектов: 2 часа",
    button: "Подробнее",
    tooltipContent: card4TooltipContent
}

const card5 = {
    header: "РАБОЧИЕ ЧЕРТЕЖИ",
    image: planImage,
    title: "Обмерный план",
    text: "7 помещений: 30 минут",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const card6 = {
    header: "ТЕХНОЛОГИЧЕСКИЕ КАРТЫ",
    image: processImage,
    title: "Кладка газобетона",
    text: "136 блоков с подрезкой: 2 часа",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const card7 = {
    header: "СМЕТА РАБОТ",
    image: worksImage,
    title: "Трудозатраты, стоимость",
    text: "Автоматический расчёт",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const card8 = {
    header: "СМЕТА МАТЕРИАЛОВ",
    image: materialsImage,
    title: "Расход, стоимость",
    text: "Автоматический расчёт",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const card9 = {
    header: "ИНСТРУМЕНТЫ",
    image: toolsImage,
    title: "Количество, стоимость",
    text: "Автоматический расчёт",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const card10 = {
    header: "ИЗОБРАЖЕНИЯ",
    image: renderImage,
    title: "Интерьер кухни",
    text: "1 ракурс Full HD: 10 минут",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

// const card11 = {
//     header: "ВИДЕО",
//     image: videoImage,
//     title: "Строительный процесс",
//     text: "1 минута видео: 8 часов",
//     button: "Подробнее"
// }

const card12 = {
    header: "ВИРТУАЛЬНЫЕ ПРОГУЛКИ",
    image: motionImage,
    title: "Прогулка по квартире",
    text: "7 помещений: 15 минут",
    button: "Подробнее",
    tooltipTitle: "",
    tooltipContent: ""
}

const miniCard1 = {
    image: reactlogo,
    alt: "reactlogo",
    title: "",
    subtitle: "Программируем на JavaScript / React / Three.js"
}

const miniCard2 = {
    image: htmllogo,
    alt: "htmllogo",
    title: "",
    subtitle: "Верстаем адаптивные сайты в HTML / CSS"
}

const miniCard3 = {
    image: smartphonelogo,
    alt: "smartphonelogo",
    title: "",
    subtitle: "Прототипируем мобильные и web-приложения"
}

const miniCard4 = {
    image: scalelogo,
    alt: "scalelogo",
    title: "",
    subtitle: "Создавать масштабируемые мобильные и Интернет-сервисы"
}

const miniCard5 = {
    image: worldlogo,
    alt: "worldlogo",
    title: "",
    subtitle: "Лицензировать свои продукты в другие страны"
}

const miniCard6 = {
    image: stockslogo,
    alt: "stockslogo",
    title: "",
    subtitle: "Привлекать инвестиции"
}

const miniCard7 = {
    image: brickslogo,
    alt: "brickslogo",
    title: "Архитектор",
    subtitle: "Autodesk Revit Architecture"
}

const miniCard8 = {
    image: meplogo,
    alt: "meplogo",
    title: "Проектировщик инженерных систем",
    subtitle: "Autodesk Revit MEP"
}

const miniCard9 = {
    image: codelogo,
    alt: "codelogo",
    title: "Программист",
    subtitle: "Autodesk Forge"
}

const miniCard10 = {
    image: httpslogo,
    alt: "httpslogo",
    title: "Web-разработчик",
    subtitle: "Интернет-сервисы"
}

const miniCard11 = {
    image: technologylogo,
    alt: "technologylogo",
    title: "Технолог",
    subtitle: "Строительство, инженерные системы, отделка"
}

const video1 = {
    link: "https://www.youtube.com/embed/TXkEG7fAR04",
    id: "blocks"
}

const video2 = {
    link: "https://www.youtube.com/embed/pkpZawD--88",
    id: "shower"
}

export const videos = { video1, video2 }

export const cards1 = [card1, card2, card3]
export const cards2 = [card4, card5, card6]
export const cards3 = [card7, card8, card9]
export const cards4 = [card10, card12]
export const miniCards1 = [miniCard1, miniCard2, miniCard3]
export const miniCards2 = [miniCard4, miniCard5, miniCard6]
export const miniCards3 = [miniCard7, miniCard8, miniCard9, miniCard10, miniCard11]