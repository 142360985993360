import React from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'   
import DesignCalculator from '../components/DesignCalculator'

const DesignCalc = () => {
    return(
        <Layout>
            <Container>
                <Row>
                    <Col md={12}>
                        <DesignCalculator></DesignCalculator>
                    </Col>
                </Row>
            </Container>
           
        </Layout>
    )
}

export default DesignCalc