import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './pages/index'
import Ramonsoler from './pages/ramonsoler'
import About from './pages/about'
import KranPage from './pages/3D'
import DesignCalc from './pages/designcalc'
import TaskCalc from './pages/taskcalc'
import TaskCalc2 from './pages/taskcalc2'
import NotFoundPage from './pages/404'
import Flat from './pages/flat'

const App = () => {
    return (
        <>
            <Switch>
                <Route exact path='/' component={Index} />
                <Route path='/ramonsoler' component={Ramonsoler} />
                <Route path='/about' component={About} />
                <Route path='/3D' component={KranPage} />
                <Route path='/smeta' component={DesignCalc} />
                <Route path='/task' component={TaskCalc} />
                <Route path='/task2' component={TaskCalc2} />
                <Route path='/flat' component={Flat} />
                <Route path='*' component={NotFoundPage} />
            </Switch>
        </>
    )
}

export default App