import React from 'react'
import { YMInitializer } from 'react-yandex-metrika';

class YandexMetrika extends React.Component {
  render() {
    return (
      <div>
          <YMInitializer accounts={[57580090]} options={{webvisor: true, triggerEvent: true}}/>
      </div>
    );
  }
};

export default YandexMetrika