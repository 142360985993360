import React, {useEffect} from 'react'

const DessignCalculator = () => {
    
    useEffect(()=>{
        (function() { 
            var j,q,d=document, 
            gi=d.getElementById,
            ce=d.createElement, 
            gt=d.getElementsByTagName,
            id="calconic_", 
            b="https://cdn.calconic.com/beta/js/";
            if(!gi.call(d,id)) { 
                j=ce.call(d,"script"); 
                j.id=id; 
                j.type="text/javascript"; 
                j.async=true;
                j.dataset.calconic=true;
                j.src=b+"calconic.min.js"; 
                q=gt.call(d,"script")[0]; 
                q.parentNode.insertBefore(j,q) 
            }
        })();
    })
    
    return (
        <>
            <div className="calconic-calculator" data-calculatorid="5e1b306f4c58a20029d3b1eb"></div>
        </>
    )
}

export default DessignCalculator