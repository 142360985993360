import React, { useEffect } from 'react'

const TaskCalculator = () => {

    useEffect(() => {
        (function () {
            var qs, j, q, s, d = document,
                gi = d.getElementById,
                ce = d.createElement,
                gt = d.getElementsByTagName,
                id = "calconic_",
                b = "https://cdn.calconic.com/static/js/";
            if (!gi.call(d, id)) {
                j = ce.call(d, "script");
                j.id = id;
                j.type = "text/javascript";
                j.async = true;
                j.dataset.calconic = true;
                j.src = b + "calconic.min.js";
                q = gt.call(d, "script")[0];
                q.parentNode.insertBefore(j, q)
            }
        })();

    })

    return (
        <>
            <div class="calconic-calculator" data-calculatorid="5f0af8223db636002920c78a"></div>
        </>
    )
}

export default TaskCalculator