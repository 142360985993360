import React, { useState } from "react"
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Tooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Workshop from '../components/workshop'
import Panorama from "../components/panorama"
import YouTube from '../components/youtube'
import image360 from '../static/images/panorama3.png';
import './mycard.css'
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css'

const MyCard = (props) => {

    const [modalInfo, showModalInfo] = useState(false)
    const [modalImage, showModalImage] = useState(false)
    const [modal3d, showModal3d] = useState(false)
    const [modalPanorama, showModalPanorama] = useState(false)
    const [modalVideo, showModalVideo] = useState(false)

    // const renderTooltip = (props) => {
    //     return <Tooltip {...props} >Двойной клик для просмотра в увеличенном окне</Tooltip>;
    // }

    const popoverTooltipTitle = (title) => {
        if (title) {
            return (
                <Popover.Title as="h3">
                    {title}
                </Popover.Title>
            )
        }
        if (props.tooltipTitle && props.tooltipTitle !== "") {
            if (typeof props.tooltipTitle === "function")
                return (
                    <Popover.Title as="h3">
                        {props.tooltipTitle()}
                    </Popover.Title>
                )
            else 
                return (
                    <Popover.Title as="h3">
                        {props.tooltipTitle}
                    </Popover.Title>
                )
        }
    }

    const popoverTooltipContent = (content) => {
        if (content) {
            return (
                <Popover.Content>
                    {content}
                </Popover.Content>
            )
        }
        if (props.tooltipContent && props.tooltipContent !== "") {
            if (typeof props.tooltipContent === "function") {
                return (
                    <Popover.Content>
                        {props.tooltipContent()}
                    </Popover.Content>
                )
            }
            else 
                return (
                    <Popover.Content>
                        {props.tooltipContent}
                    </Popover.Content>
                )
            
        }
    }

    const renderPopoverTooltipContent = (title) => {
        return (
            <Popover id="popover-contained">
                {popoverTooltipContent(title)}
            </Popover>
        )
    }

    const renderPopoverTooltip = () => {
        return (
            <Popover id="popover-contained">
                {popoverTooltipTitle()}
                {popoverTooltipContent()}
            </Popover>
        )
    }

    // Подсказка в мобильной версии
    const mobileTooltip = () => {
        if (navigator.userAgent.match(/Android|Phone|iPad|iPod/i) && (props.tooltipTitle || props.tooltipContent)) {
            return (
                <OverlayTrigger
                    placement="top-end"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderPopoverTooltip()}
                >
                    <Col xs={2}>
                        <i class="fas fa-question align-right" style={{ float: "right" }}></i>
                    </Col>
                </OverlayTrigger>
            )
        }
    }

    // Заголовок карточки
    const header = () => {
        if (props.header !== "") {
            return (
                <Card.Header style={{ textAlign: "center" }}>
                    <Container>
                        <Row>
                            <Col xs={10}><b style={{ float: "left" }}>{props.header}</b></Col>
                            {mobileTooltip()}
                        </Row>
                    </Container>


                </Card.Header>
            )
        }
    }

    // Вывод изображения
    const image = () => {
        if (navigator.userAgent.match(/Android/i)) {
            return (
                <Card.Img src={props.content} variant="top" style={{ cursor: 'pointer' }} onClick={() => { showModalImage(!modalImage) }}></Card.Img>
            )
        } else {
            return (
                <OverlayTrigger
                    placement="top-end"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderPopoverTooltip()}
                >
                    <Card.Img src={props.content} variant="top" style={{ cursor: 'pointer' }} onClick={() => { showModalImage(!modalImage) }}></Card.Img>
                </OverlayTrigger>
            )
        }
    }

    // Нижняя часть карточки
    const cardBody = () => {
        return (
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
                {/* <Button style={{float: "right"}} variant="secondary" onClick={() => {showModalInfo(!modalInfo)}}>{props.button}</Button> */}
            </Card.Body>
        )
    }

    // Модальное окно - просмотр изображения
    const imageModal = () => {
        return (
            <Modal centered show={modalImage} onHide={() => { showModalImage(!modalImage) }} size="xl">
                <Modal.Body>
                    <Card.Img src={props.content} variant="top"></Card.Img>
                </Modal.Body>
            </Modal>
        )
    }

    // Модальное окно - просмотр Workshop
    const workshopModal = () => {
        return (
            <Modal centered show={modal3d} onHide={() => { showModal3d(!modal3d) }} size="xl">
                <Modal.Body>
                    <Workshop containerId={props.children.props.containerId + "Modal"}></Workshop>
                </Modal.Body>
            </Modal>
        )
    }

    // Модальное окно - просмотр панорам
    const panoramaModal = () => {
        return (
            <Modal centered show={modalPanorama} onHide={() => { showModalPanorama(!modalPanorama) }} size="xl">
                <Modal.Body>
                    <Panorama image={image360} containerId={props.children.props.containerId + "Modal"}></Panorama>
                </Modal.Body>
            </Modal>
        )
    }

    // Модальное окно - просмотр видео
    const videoModal = () => {
        return (
            <Modal centered show={modalVideo} onHide={() => { showModalVideo(!modalVideo) }} size="xl">
                <Modal.Body>
                    <YouTube id={props.children.props.id + "Modal"} link={props.children.props.link}></YouTube>
                </Modal.Body>
            </Modal>
        )
    }

    // Модальное окно - просмотр "подробнее"
    const infoModal = () => {
        return (
            <Modal centered show={modalInfo} size="md">
                <Modal.Body>
                    {props.more}
                    <Button onClick={() => { showModalInfo(!modalInfo) }}>Закрыть</Button>
                </Modal.Body>
            </Modal>
        )
    }

    // Формирование карточки в зависимости от входящего элемента
    const layout = () => {
        if (props.children !== undefined) {
            if (props.type === "video") {
                return (
                    <Card className="card" >
                        {header()}
                        <div role="contentinfo" onClick={() => { showModalVideo(!modalVideo) }} onKeyDown={() => { showModalPanorama(!modalPanorama) }}>
                            {props.children}
                        </div>
                        {cardBody()}
                        {infoModal()}
                        {videoModal()}
                    </Card>
                )
            }
            if (props.type === "panorama") {

                return (
                    <Card className="card">
                        {header()}
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderPopoverTooltipContent("Двойной клик для увеличения")}
                        >
                            <div role="contentinfo" onDoubleClick={() => { showModalPanorama(!modalPanorama) }} onKeyDown={() => { showModalPanorama(!modalPanorama) }}>
                                {props.children}
                            </div>
                        </OverlayTrigger>
                        {cardBody()}
                        {infoModal()}
                        {panoramaModal()}
                    </Card>
                )
            }
            if (props.type === "workshop") {
                return (
                    <Card className="card">
                        {header()}
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderPopoverTooltipContent("Двойной клик для увеличения")}
                        >
                            <div role="contentinfo" onDoubleClick={() => { showModal3d(!modal3d) }} onKeyDown={() => { showModal3d(!modal3d) }}>
                                {props.children}
                            </div>
                        </OverlayTrigger>
                        {cardBody()}
                        {infoModal()}
                        {workshopModal()}
                    </Card>
                )
            }
        }
        if (props.children === undefined) {
            return (
                <Card className="card">
                    {header()}
                    {image()}
                    {cardBody()}
                    {infoModal()}
                    {imageModal()}
                </Card>
            )
        }
    }
    return (
        <>
            {layout()}
        </>
    )
}

export default MyCard