import React, {useEffect} from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {

    useEffect(() => {
      const autoHeight = () => {
        const titleBlock = document.getElementById("titleBlock").clientHeight;
        const footer = document.getElementById("footer").clientHeight;
        const documentHeight = document.documentElement.clientHeight;
        document.getElementById("content").style.height = documentHeight - titleBlock - footer + "px";
      }
      autoHeight();
    })
  
    return (
    <Layout >
      <h4 className="display-4" style={{textAlign: "center"}}>Страница не найдена</h4>
    </Layout>
  )
}

export default NotFoundPage
