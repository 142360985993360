import React from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TaskCalculator2 from '../components/TaskCalculatorNew'

const TaskCalc2 = () => {
    return (
        <Layout>
            <Container>
                <Row>
                    <Col md={12}>
                        <TaskCalculator2 />
                    </Col>
                </Row>
            </Container>

        </Layout>
    )
}

export default TaskCalc2